@import '../_sass/mixins';

$primary: #0a0a0a;
$secondary: #FF7B7B;

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

#photo a {
  font-family: 'Source Sans Pro', sans-serif;
  color:#c22; text-decoration:none;
  font-size:1.2em;
  font-weight: 400;
}

#photo a:hover {
  text-decoration:underline;
}

#photo ul {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;
    border-top: 1px solid #eee;
}

#photo {
  padding-right:30px;
}

#photo > div {
  float:right;
}

#photo li {
  list-style:none;
  margin:0 0 10px;
  padding:0 0 10px 10px;
  color:#888;
  border-bottom:1px solid #eee;
}


body {
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

article {
  margin: 5em 0;
}

p {
  line-height: 1.8;
  font-size: 1.3em;
}

a {
  @include transition(color .2s ease-in-out);
  &:hover {
    color: #FF5B33;
    text-decoration: none;
  }
}

button.btn, input.btn {
  padding: 12px 35px;
  margin: 2em 0.5em;
  border: solid 1px #d5e0e7;
  border-radius: 4px;
  color: #2E4453;
  font-weight: 600;
  &:hover, &.btn-default:hover {
    background-color: white;
    border: solid 1px #b0c5d2;
    -moz-box-shadow: 0 1px 0 #b0c5d2;
    -webkit-box-shadow: 0 1px 0 #b0c5d2;
    box-shadow: 0 1px 0 #b0c5d2;
  }
  i {
    padding-right: 0.5em;
  }
  @include bp-xsmall {
    @include horizontal-align;
    margin-bottom: 0.5em;
  }
}

.main {
  background-color: white;
  border-left: solid 1px #cedfea;
  border-right: solid 1px #cedfea;
  &.container {
    padding: 0;
  }
}

.section-jump {
  position: relative;
  top: 0;
  color: #C8D7E1;
  margin: 0 auto;
  display: table;
  @include transition(top .2s ease-in);
  &:hover {
    top: 3px;
  }
}

/*--------------------------------------------------------------
	2.0 Home Hero
--------------------------------------------------------------*/
.hero-container {
  margin-top: 0em;
}

.hero-content {
  width: 68%;
  margin: 0 auto;
  display: table;
  @include bp-medium {
    width: 100%;
  }
  @include bp-small {
    width: 100%;
  }
  @include bp-xsmall {
    width: 100%;
  }
  h1 {
    font-size: 48px;
  }
  p {
    margin: 1em 0 1em 0;
  }
  .hero-buttons {
    margin: 0 auto;
    display: table;
    i {
      padding-right: 0.5em;
    }
  }
}

/*--------------------------------------------------------------
	2.1 Contact
--------------------------------------------------------------*/
.modal-header {
  border-bottom: none;
  button {
    margin-bottom: 0;
  }
}

.modal-content {
  border-radius: 2px;
}

.modal-body {
  margin: 0 20px;
  label {
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 0.5em;
  }
  textarea, input {
    border-color: #e6e6e6;
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 1em;
  }
  .form-group {
    margin: 0;
  }
  .form-control {
    padding: 25px;
    font-size: 16px;
  }
  input[type="submit"]{
    float: right;
    margin-right: 0;
  }
  ::-webkit-input-placeholder {
    color: #D6D6D6;
  }
  :-moz-placeholder {
    color: #D6D6D6;
  }
  ::-moz-placeholder {
    color: #D6D6D6;
  }
  :-ms-input-placeholder {
    color: #D6D6D6;
  }
}

/*--------------------------------------------------------------
	3.0 Posts
--------------------------------------------------------------*/
#portfolio .container {
  margin-top: 10em;
  @include bp-xsmall {
    margin-top: 2em;
  }
  .break {
    margin: 8em 0;
    @include bp-xsmall {
      margin: 2em 0;
    }
  }
}

.section-title {
  position: relative;
  margin: 0 auto;
  margin-bottom: 1em;
  &:after {
    content: '';
    position: absolute;
    top: 1.35em;
    width: 3em;
    height: 3px;
    background-color: #ECEFF1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.section-subtitle {
  width: 40%;
  margin: 0 auto;
  @include bp-xsmall {
    width: 100%;
  }
  @include bp-small {
    width: 60%;;
  }
}

.content-wrap {
  margin-bottom: 8em;
  @include bp-xsmall {
    margin-bottom: 2em;
  }
  @include bp-small {
    margin-bottom: 2em;
  }
  img.img-responsive {
    @include bp-xsmall {
      display: none;
    }
    @include bp-small {
      display: none;
    }
  }
  .content {
    margin-top: 5em;
    margin-left: 5em;
    @include bp-xsmall {
      margin-left: 0;
    }
    h3.title {
      position: relative;
      margin-bottom: 1.5em;
      &:after {
        content: '';
        position: absolute;
        top: 1.5em;
        left: 0;
        width: 3em;
        height: 3px;
        background-color: #ECEFF1;
      }
    }
    .excerpt {
      width: 80%;
      @include bp-xsmall {
        width: 100%;
      }
    }
    button{
      font-size: 16px;
      margin-left: 0;
      margin-bottom: 1em;
      margin-top: 1em;
    }
    .categories span {
      color: #b9b1ad;
      background-color: #EFEBE9;
    }
  }
}

.tags, .categories {
  margin: 2em 0;
  span {
    color: #6B7E8A;
    background-color: #ECEFF1;
    padding: 0.5em 1.5em;
    display: inline-block;
    margin-bottom: 0.5em;
  }
  span:hover {
    color: #6B7E8A;
    background-color: #D1E4F0;
  }
}

/*--------------------------------------------------------------
	4.0 Footer
--------------------------------------------------------------*/
.site-footer {
  font-size: 14px;
  margin-top: 2em;
  border-top: 1px solid #efefef;
  padding: 1em 0;
  p {
    font-size: 12px;
    font-weight: 600;
    color: #9eb1bd;
    margin: 0;
    padding: 10px 0;
    @include bp-xsmall {
      text-align: center;
    }
  }
  .section-jump {
    float: right;
    @include bp-xsmall {
      float: none;
    }
  }
}

/*  Code */

.highlight  {
  background: #ffffff;
  display: inline-block;
  margin: auto;
  padding-bottom: 20px;
}

.highlight pre {
    margin-bottom: 0;
}

.highlighter-rouge {
  display: inline-block;
}

.highlight .c { color: #999988; font-style: italic } /* Comment */
.highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */
.highlight .k { font-weight: bold } /* Keyword */
.highlight .o { font-weight: bold } /* Operator */
.highlight .cm { color: #999988; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #999999; font-weight: bold } /* Comment.Preproc */
.highlight .c1 { color: #999988; font-style: italic } /* Comment.Single */
.highlight .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
.highlight .gd .x { color: #000000; background-color: #ffaaaa } /* Generic.Deleted.Specific */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gr { color: #aa0000 } /* Generic.Error */
.highlight .gh { color: #999999 } /* Generic.Heading */
.highlight .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
.highlight .gi .x { color: #000000; background-color: #aaffaa } /* Generic.Inserted.Specific */
.highlight .go { color: #888888 } /* Generic.Output */
.highlight .gp { color: #555555 } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #aaaaaa } /* Generic.Subheading */
.highlight .gt { color: #aa0000 } /* Generic.Traceback */
.highlight .kc { font-weight: bold } /* Keyword.Constant */
.highlight .kd { font-weight: bold } /* Keyword.Declaration */
.highlight .kp { font-weight: bold } /* Keyword.Pseudo */
.highlight .kr { font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #445588; font-weight: bold } /* Keyword.Type */
.highlight .m { color: #009999 } /* Literal.Number */
.highlight .s { color: #d14 } /* Literal.String */
.highlight .na { color: #008080 } /* Name.Attribute */
.highlight .nb { color: #0086B3 } /* Name.Builtin */
.highlight .nc { color: #445588; font-weight: bold } /* Name.Class */
.highlight .no { color: #008080 } /* Name.Constant */
.highlight .ni { color: #800080 } /* Name.Entity */
.highlight .ne { color: #990000; font-weight: bold } /* Name.Exception */
.highlight .nf { color: #990000; font-weight: bold } /* Name.Function */
.highlight .nn { color: #555555 } /* Name.Namespace */
.highlight .nt { color: #000080 } /* Name.Tag */
.highlight .nv { color: #008080 } /* Name.Variable */
.highlight .ow { font-weight: bold } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mf { color: #009999 } /* Literal.Number.Float */
.highlight .mh { color: #009999 } /* Literal.Number.Hex */
.highlight .mi { color: #009999 } /* Literal.Number.Integer */
.highlight .mo { color: #009999 } /* Literal.Number.Oct */
.highlight .sb { color: #d14 } /* Literal.String.Backtick */
.highlight .sc { color: #d14 } /* Literal.String.Char */
.highlight .sd { color: #d14 } /* Literal.String.Doc */
.highlight .s2 { color: #d14 } /* Literal.String.Double */
.highlight .se { color: #d14 } /* Literal.String.Escape */
.highlight .sh { color: #d14 } /* Literal.String.Heredoc */
.highlight .si { color: #d14 } /* Literal.String.Interpol */
.highlight .sx { color: #d14 } /* Literal.String.Other */
.highlight .sr { color: #009926 } /* Literal.String.Regex */
.highlight .s1 { color: #d14 } /* Literal.String.Single */
.highlight .ss { color: #990073 } /* Literal.String.Symbol */
.highlight .bp { color: #999999 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #008080 } /* Name.Variable.Class */
.highlight .vg { color: #008080 } /* Name.Variable.Global */
.highlight .vi { color: #008080 } /* Name.Variable.Instance */
.highlight .il { color: #009999 } /* Literal.Number.Integer.Long */

/* Make line numbers unselectable: excludes line numbers from copy-paste user ops */
.highlight .lineno {-webkit-user-select: none;-moz-user-select: none; -o-user-select: none;}
.lineno::-moz-selection {background-color: transparent;} /* Mozilla specific */
.lineno::selection {background-color: transparent;} /* Other major browsers */


/* Pretty Table  */
table.dataTable {
    margin-left:auto;
    margin-right:auto;
    background-color: transparent;
}
table.dataTable th {
    text-align: left;
}
table.dataTable {
    max-width: 90%;
    margin-bottom: 5px;
    border: 1px solid #dedede;
}

table.dataTable> thead > tr > th,
table.dataTable > tbody > tr > th,
table.dataTable > tfoot > tr > th,
table.dataTable > thead > tr > td,
table.dataTable > tbody > tr > td,
table.dataTable > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}
table.dataTable > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    text-transform: none;
    background-color: #777;
    color: white;
    text-align: left;
}
table.dataTable > caption + thead > tr:first-child > th,
table.dataTable > colgroup + thead > tr:first-child > th,
table.dataTable > thead:first-child > tr:first-child > th,
table.dataTable > caption + thead > tr:first-child > td,
table.dataTable > colgroup + thead > tr:first-child > td,
table.dataTable > thead:first-child > tr:first-child > td
{
    border-top: 0;
}

table.dataTable > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

table.dataTable col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}
table.dataTable td[class*="col-"],
table.dataTable th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}


table.dataTable td:first-child {
    width: 150px;
}

table.dataTable tr td {
    hyphens: auto;
}

.centeredbox{
    width:100%;

    /* Firefox */
    display:-moz-box;
    -moz-box-pack:center;
    -moz-box-align:center;

    /* Safari and Chrome */
    display:-webkit-box;
    -webkit-box-pack:center;
    -webkit-box-align:center;

    /* W3C */
    display:box;
    box-pack:center;
    box-align:center;

    padding-top: 20px;
    padding-bottom: 30px;
}

body {
  counter-reset: figures;
}

figure.highlight {
  padding-bottom: 20px;
}

figure {
  figcaption::before {
    counter-increment: figures;
    content: "Figure " counter(figures) ": ";
  }
  figcaption {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: rgb(100, 100, 100);
  }
  figure.highlight {
    padding-bottom: 0px;
  }
}


table {
  caption::before {
    counter-increment: figures;
    content: "Figure " counter(figures) ": ";
  }
  caption {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: rgb(100, 100, 100);
  }
}
